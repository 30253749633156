.contact-wrapper {
  background-color: rgb(245, 245, 245);
  padding-bottom: 100px;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info {
  width: 40%;
  text-align: center;
}

.contact-info h1 {
  margin-top: 4rem;
}

.contact-info .horizontal-line {
  display: block;
  width: 40%;
  border: 0;
  border-top: 4px solid #be1e1e;
  margin: 1.5rem auto;
  padding: 0;
}

.company-info {
  text-align: center;
  margin-top: 2rem;
}

.company-info p {
  color: rgb(124, 124, 124);
}

/* Responsive */
@media screen and (max-width: 970px) {
  .contact-info {
    width: 70%;
  }
}
@media screen and (max-width: 760px) {
  .contact-info {
    width: 80%;
    margin-bottom: 30px;
  }

  .contact-info .horizontal-line {
    width: 80%;
  }
}
