.contact-info p {
  margin-top: 2rem;
}

.contact-alt-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.contact-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 150px;
}

.contact-alt a {
  color: black;
  transition: all ease-out 0.2s;
}

.contact-alt a:hover {
  color: rgb(184, 184, 184);
}

.contact-icon {
  margin-bottom: 1rem;
}

/* Responsive */

@media screen and (max-width: 550px) {
  .contact-alt-container {
    flex-direction: column;
  }
  .contact-alt {
    width: 100%;
  }
}
