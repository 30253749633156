.home-wrapper h1 {
  font-size: 30px;
  font-weight: 400;
  color: #242424;
  text-align: center;
  padding: 1rem;
}

.home-wrapper .horizontal-line {
  display: block;
  width: 15%;
  border: 0;
  border-top: 4px solid #be1e1e;
  margin: 0.5rem auto;
  padding: 0;
}

.home-wrapper .references-container {
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.home-references h1 {
  font-size: 24px;
}

.home-partners {
  border-top: 1px solid lightgrey;
  background-color: rgb(245, 245, 245);
  width: 100%;
}

.home-partners h1 {
  font-size: 24px;
  padding: 1rem;
}

.home-partners .home-partners-container {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.home-partners .home-partners-container .partner {
  margin: 1rem;
}

.home-partners .home-partners-container .partner img {
  width: 200px;
  height: 40px;
  object-fit: contain;
}

.home-partners .home-partners-container .partner h2 {
  width: 200px;
  font-weight: 600;
}

@media screen and (max-width: 1100px) {
  .home-wrapper .horizontal-line {
    width: 25%;
  }
  .home-wrapper .references-container {
    grid-template-columns: 1fr 1fr;
  }
  .home-partners .home-partners-container {
    grid-template-columns: 1fr 1fr;
  }
  .home-partners .home-partners-container .partner h2 {
    text-align: center;
  }
}

@media screen and (max-width: 740px) {
  .home-wrapper .horizontal-line {
    width: 50%;
  }
  .home-wrapper .references-container {
    grid-template-columns: 1fr;
  }
  .home-partners .home-partners-container {
    grid-template-columns: 1fr;
  }
}
