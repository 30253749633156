.about-wrapper {
  background-color: rgb(245, 245, 245);
}

.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.about-container h1 {
  padding-top: 2rem;
  color: #242424;
}

.about-container .horizontal-line {
  display: block;
  width: 20%;
  border: 0;
  border-top: 4px solid #be1e1e;
  margin: 1.5rem 0;
  padding: 0;
}

.about-container .horizontal-line-two {
  display: block;
  width: 20%;
  border: 0;
  border-top: 4px solid #be1e1e;
  margin-bottom: 1.5rem;
  padding: 0;
}

.about-container > p {
  padding-bottom: 1.5rem;
  color: #242424;
}

.about-container .link {
  color: #be1e1e;
  transition: all ease-out 0.2s;
}

.about-container .link:hover {
  color: #cc8d8d;
}

.about-container .developer-credits {
  color: rgb(153, 153, 153);
  font-size: 14px;
}

.about-container .developer-credits a {
  color: rgb(153, 153, 153);
  transition: all ease-out 0.2s;
}

.about-container .developer-credits a:hover {
  color: #242424;
}

.about-container .avatar-image {
  width: 60%;
}
.about-container .avatar-image img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 769px) {
  .about-container .horizontal-line {
    width: 80%;
  }
  .about-container > p {
    font-size: 18px;
  }
  .about-container .avatar-image {
    width: 100%;
  }
}
