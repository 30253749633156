.hero-container {
  height: 55vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../images/tradack-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  border-bottom: 1px solid rgb(201, 201, 201);
}

.hero-container > h1 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
}

.hero-container .hero-services {
  display: block;
  margin-top: 10px;
  color: #ffffff;
  font-size: 18px;
}

.hero-btns {
  margin-top: 32px;
}
.hero-btns .btn {
  margin: 6px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 24px;
  }
  .hero-container > p {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 24px;
  }
  .hero-container > p {
    font-size: 20px;
  }
  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .hero-container .hero-services {
    display: none;
  }
}
