.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #be1e1e;
  color: #ffffff;
  transition: all 0.2s ease-out;
}

.btn--outlined {
  background-color: transparent;
  color: rgb(255, 255, 255);
  padding: 8px 20px;
  border: 1px solid #ffffff;
  transition: all 0.2s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 1rem;
}

.btn--large {
  padding: 12px 26px;
  font-size: 1rem;
}

.btn--primary:hover {
  background-color: #801515;
}

.btn--outlined:hover {
  color: #aaaaaa;
}
