.card {
  height: auto;
  width: 350px;
  margin-bottom: 2rem;
  background: white;
  transition: 0.2s;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.card:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.card-image {
  height: 250px;
  width: 100%;
}

.card-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.card .text {
  padding: 20px;
}

.card .text .reference {
  margin-bottom: 0.3rem;
  font-size: 21px;
  font-weight: 400;
  color: #202020;
}

.card .text p {
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 400;
  color: #e74c3c;
  line-height: 20px;
}

@media screen and (max-width: 760px) {
  .card .text p {
    font-size: 18px;
  }
}
