.reference-info-wrapper {
  background-color: rgb(245, 245, 245);
  padding-bottom: 2rem;
}

.reference-info-container {
  max-width: 1300px;
  margin: 0 auto;
}

.reference-info-container .reference-text {
  text-align: center;
  padding: 2rem;
  width: 70%;
  margin: 0 auto;
}

.reference-info-container .reference-text h1 {
  color: #242424;
  padding: 0.5rem;
}

.reference-info-container .reference-text h3 {
  color: #242424;
  font-weight: 400;
  padding: 0.5rem;
}

.reference-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.reference-image-container .reference-image {
  align-self: center;
  justify-self: center;
  padding: 1rem;
  height: 500px;
  width: 100%;
}

.reference-image-container .reference-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.reference-text .horizontal-line {
  display: block;
  width: 30%;
  border: 0;
  border-top: 4px solid #be1e1e;
  margin: 0.5rem auto;
  padding: 0;
}

/* responsive */
@media screen and (max-width: 900px) {
  .reference-info-container .reference-text {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }
  .reference-text .horizontal-line {
    width: 40%;
    margin: 0.5rem 0;
  }
  .reference-image-container {
    grid-template-columns: 1fr 1fr;
  }
  .reference-image-container .reference-image {
    height: 300px;
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .reference-image-container {
    grid-template-columns: 1fr;
  }
  .reference-text .horizontal-line {
    width: 50%;
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 450px) {
  .reference-image-container {
    grid-template-columns: 1fr;
  }
  .reference-text .horizontal-line {
    width: 80%;
    margin: 0.5rem 0;
  }
}
