footer {
  width: 100%;
  bottom: 0;
  background: #696969;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  padding: 1rem;
}

footer h1 {
  font-size: 28px;
  font-weight: 700;
  margin: 1.5rem auto;
}

.footer-links {
  display: flex;
  list-style-type: none;
}

.footer-links li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  transition: all ease-out 0.2s;
}

.footer-links li a:hover {
  color: rgb(156, 156, 156);
}

.footer-contact-alts {
  padding: 2rem;
  display: flex;
  list-style-type: none;
}

.footer-contact-alts li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  transition: all ease-out 0.2s;
}

.footer-contact-alts li:first-child {
  margin-right: 1.5rem;
}

.footer-contact-alts li a:hover {
  color: rgb(156, 156, 156);
}

footer .credits {
  font-size: 12px;
  color: #afafaf;
  margin-bottom: 1rem;
}

footer .credits a {
  color: #afafaf;
  transition: all ease-out 0.2s;
}

footer .credits a:hover {
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 450px) {
  .footer-contact-alts {
    flex-direction: column;
  }
  .footer-contact-alts li:last-child {
    margin-top: 20px;
  }
}
