.references-wrapper {
  background-color: rgb(245, 245, 245);
}

.references-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 1rem;
}

.references-container h1 {
  color: #242424;
  text-align: center;
  padding-top: 2rem;
}

.references-container .horizontal-line {
  display: block;
  margin: 0 auto;
  width: 20%;
  border: 0;
  border-top: 4px solid #be1e1e;
  margin: 1.5rem auto;
  padding: 0;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

@media screen and (max-width: 1100px) {
  .cards-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 740px) {
  .cards-container {
    grid-template-columns: 1fr;
  }
  .references-container h1 {
    color: #242424;
    text-align: left;
    padding-top: 2rem;
  }
  .references-container .horizontal-line {
    width: 80%;
    margin: 1.5rem 0;
  }
}
